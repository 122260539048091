import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "axios";
import { IBilling, ITotalOwe } from "../../../models/interfaces/IBilling";
import { ApiError } from "../../../models/store/payload-types/payloadTypes";

export const getInvoices = createAsyncThunk(
    //action type string
    "invoices/getInvoices",
    //callback function
    async (
        {
            ess_id,
            page,
            month_filter,
        }: { ess_id: string; page: number; month_filter: string },

        thunkAPI,
    ): Promise<IBilling[] | any> => {
        try {
            const { data } = await (api as AxiosInstance).get(
                `/electricity-supply-service/billing/billing/${ess_id}`,
                {
                    params: {
                        page: page,
                        month_filter: month_filter,
                    },
                },
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response);
        }
    },
);

export const getTotalOwed = createAsyncThunk(
    "[Invoices] Get Total Owed",
    async (
        { ess_id, month_filter }: { ess_id: string; month_filter: string },
        thunkAPI,
    ) => {
        try {
            const response = await api.get<ITotalOwe>(
                `/electricity-supply-service/billing/billing/total_owe/${ess_id}`,
                {
                    params: {
                        month_filter: month_filter,
                    },
                },
            );
            return { data: response.data, status: response.status };
        } catch (error) {
            const apiError = error as ApiError;
            return thunkAPI.rejectWithValue(apiError.response);
        }
    },
);

export const downloadInvoiceGroup = createAsyncThunk(
    "invoices/downloadInvoiceGroup",
    async (invoiceIds: string[], thunkAPI: any) => {
        let path = "/billing/invoice-documents-merge/";
        for (var i = 0; i < invoiceIds.length; i++) {
            if (i == 0) {
                path += "?invoices_ids=" + invoiceIds[i];
            } else {
                path += "&invoices_ids=" + invoiceIds[i];
            }
        }
        await api
            .get(path, {
                responseType: "arraybuffer",
            })
            .then((response) => {
                const file = new Blob([response.data], {
                    type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = `${new Date().getTime()}.pdf`;
                link.click();
                URL.revokeObjectURL(fileURL);
            })
            .catch((err) => {
                const apiError = err as ApiError;
                return thunkAPI.rejectWithValue(apiError.response);
            });
    },
);
