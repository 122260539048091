import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../../../models/store/StateStorage";
import {
  deleteTagRelationship,
  searchTagRelationships,
} from "../../../../../store/actions/tags/tags.actions";
import { removeTagFromService } from "../../../../../store/slices/informationSlice";
import {
  cleanDeleteTagsState,
  deleteTag,
} from "../../../../../store/slices/tagsSlice";
import { AppDispatch } from "../../../../../store/store";

export type ModalChangePlanProps = {
  open: boolean;
  handleClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  name: string | null;
  electricitySupplyServiceServiceId?: string;
};

export const ModalDeleteTag = ({
  open,
  handleClose,
  name,
  electricitySupplyServiceServiceId,
}: ModalChangePlanProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    searchTagRelationshipsResult,
    isLoadingSearchTagRelationshipsResult,
  } = useSelector((state: StateStorage) => state.tags);

  useEffect(() => {
    if (open && name && electricitySupplyServiceServiceId) {
      dispatch(
        searchTagRelationships({
          name: name,
          electricity_supply_service_id: electricitySupplyServiceServiceId,
        })
      );
    }
  }, [dispatch, open, name, electricitySupplyServiceServiceId]);

  const handleDelete = async () => {
    if (searchTagRelationshipsResult.items.length > 0) {
      try {
        const tagIdRelationship = searchTagRelationshipsResult.items[0].id;
        const tagId = searchTagRelationshipsResult.items[0].tag.id;
        await dispatch(deleteTagRelationship(tagIdRelationship)).unwrap();
        dispatch(deleteTag({ listName: "tagsRelationshipService", tagId }));
        dispatch(removeTagFromService({ tagId: tagId }));
        dispatch(cleanDeleteTagsState());
        if (handleClose) {
          handleClose({}, "backdropClick");
        }
      } catch (error) {
        if (handleClose) {
          handleClose({}, "backdropClick");
        }
        console.error("Failed to delete tag relationship:", error);
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-container">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            color={enerbitColors.primary.main}
            align="center"
            fontWeight="400"
          >
            ¿Deseas eliminar esta etiqueta asociada al servicio?
          </Typography>
        </Box>
        <Grid
          container
          spacing={4}
          sx={{ marginTop: "-1rem", ml: "0rem", width: "100%" }}
        >
          <Grid item xs={6} style={{ paddingLeft: "0rem" }}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => {
                if (handleClose) {
                  handleClose({}, "backdropClick");
                }
              }}
            >
              No, me equivoqué
            </Button>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: "1rem" }}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              disabled={isLoadingSearchTagRelationshipsResult}
            >
              Si, eliminar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
