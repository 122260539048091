import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  DownloadOutlinedIcon,
  IconButton,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  LoadingButton,
  MonetizationOnOutlinedIcon,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  enerbitColors,
  formatterPeso,
} from "@enerbit/base";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  IBillingDetail,
  IBillingInvoice,
} from "../../../../../../models/interfaces/IBilling";
import { StateStorage } from "../../../../../../models/store/StateStorage";
import {
  downloadInvoiceGroup,
  getInvoices,
} from "../../../../../../store/actions/billing/billing.actions";
import { AppDispatch } from "../../../../../../store/store";
import { CardItemTableInvoice } from "./CardItemTableInvoice";

export const TableInvoice = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const { billing, essId, isLoadingInvoices, yearFilter } = useSelector(
    (state: StateStorage) => state.billing
  );
  const [loadingButtons, setLoadingButtons] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(billing?.page ?? 1);

  const handleRowClick = (index: number) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [index]: !prevOpenRows[index],
    }));
  };

  const handleDownloadClick = (index: number, documentIds: string[]) => {
    setLoadingButtons((prev) => [...prev, index]);
    dispatch(downloadInvoiceGroup(documentIds)).finally(() => {
      setLoadingButtons((prev) => prev.filter((i) => i !== index));
    });
  };

  const handlePageChange = (_event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    dispatch(
      getInvoices({
        ess_id: essId,
        page: value,
        month_filter: yearFilter,
      })
    );
  };

  const getItemColor = (items: IBillingInvoice[]): string => {
    const hasCreditNote = items.some(
      (item) => item.document_type === "credit_note"
    );
    const hasDebitNote = items.some(
      (item) => item.document_type === "debit_note"
    );

    if (hasCreditNote && hasDebitNote) {
      return "warning";
    } else if (hasDebitNote) {
      return "error";
    } else if (hasCreditNote) {
      return "success";
    } else {
      return "primary";
    }
  };

  const getMappedColor = (
    color: string
  ): "primary" | "error" | "success" | "secondary" => {
    switch (color) {
      case "warning":
        return "secondary"; // Mapea a un color predefinido
      case "error":
        return "error";
      case "success":
        return "success";
      case "primary":
        return "primary";
      default:
        return "primary";
    }
  };

  const totalPages = (billing?.page ?? 1) + (billing?.next_pages ?? 0);

  const formatDate = (dateString: string): string => {
    const [year, month] = dateString.split("-");
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const monthName = months[parseInt(month, 10) - 1];
    return `${monthName} / ${year}`;
  };

  if (isLoadingInvoices) {
    return (
      <Box className="Loading-flex">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TableContainer sx={{ boxShadow: "none", mt: "20px" }} component={Paper}>
        <Table className="Table-invoice-settlement">
          <TableHead>
            <TableRow
              sx={{
                borderRadius: "8px",
              }}
            >
              <TableCell align="left">Emisión de factura</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="left">Pendiente por pagar</TableCell>
              <TableCell align="left">Reliquidación</TableCell>
              <TableCell align="left">Descarga factura</TableCell>
              <TableCell align="left"> </TableCell>
            </TableRow>
          </TableHead>
          {isLoadingInvoices ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    minHeight: "200px", // Ajusta según sea necesario
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              {billing &&
                billing.items?.map((value: IBillingDetail, index: number) => (
                  <>
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: enerbitColors.neutral[100],
                        borderRadius: "8px",
                      }}
                    >
                      <TableCell
                        className="Table-invoices-td"
                        sx={{
                          fontWeight: "700",
                          borderRadius: "15px 0px 0px 15px",
                          borderBottom: "none",
                        }}
                      >
                        {" "}
                        {formatDate(value.issued_at!)}
                      </TableCell>
                      <TableCell
                        className="Table-invoices-td"
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        {formatterPeso.format(value.total_payable_amount!)}
                      </TableCell>
                      <TableCell
                        className="Table-invoices-td"
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        {formatterPeso.format(value.total_owe_amount!)}
                      </TableCell>
                      <TableCell
                        className="Table-invoices-td"
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Button
                          variant="contained"
                          color={getMappedColor(getItemColor(value.items!))}
                          sx={{
                            padding: "0.6rem 0rem !important",
                            minWidth: "50px",
                          }}
                        >
                          <MonetizationOnOutlinedIcon />
                        </Button>
                      </TableCell>
                      <TableCell
                        className="Table-invoices-td"
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <LoadingButton
                          key={index}
                          fullWidth
                          variant="outlined"
                          color="secondary"
                          disabled={false}
                          loading={loadingButtons.includes(index)}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownloadClick(
                              index,
                              value.items!.map(
                                (document) => document.document_id!
                              )
                            );
                          }}
                        >
                          <DownloadOutlinedIcon
                            sx={{ fontSize: "20px", mr: "3px" }}
                          />
                          Factura
                        </LoadingButton>
                        {/* <Button
                      variant="outlined"
                      color="secondary"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          downloadInvoiceGroup(
                            value.items!.map(
                              (document) => document.document_id!
                            )
                          )
                        );
                      }}
                    >
                      <DownloadOutlinedIcon
                        sx={{ fontSize: "20px", mr: "3px" }}
                      />
                      Factura
                    </Button> */}
                      </TableCell>
                      <TableCell
                        className="Table-invoices-td"
                        sx={{
                          borderRadius: "0px 15px 15px 0px",
                          borderBottom: "none",
                        }}
                      >
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleRowClick(index)}
                        >
                          {openRows[index] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow key={index}>
                      <TableCell
                        colSpan={6}
                        sx={{
                          borderBottom: "none",
                          padding: "6px",
                          backgroundColor: "white !important",
                        }}
                      >
                        {value.items!.map((document: IBillingInvoice) => (
                          <Collapse
                            key={document.document_id}
                            in={openRows[index]}
                            timeout="auto"
                            unmountOnExit
                            sx={{ background: "white" }}
                          >
                            <Box marginLeft="5%">
                              <CardItemTableInvoice document={document} />
                            </Box>
                          </Collapse>
                        ))}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          )}
        </Table>
        {!isLoadingInvoices && (billing?.items?.length ?? 0) > 0 && (
          <Box className="Pagination-tables">
            <Pagination
              count={totalPages}
              shape="rounded"
              page={currentPage}
              sx={{ margin: "0.3rem 0 1rem 0" }}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </TableContainer>
    </>
  );
};
