import { styled } from "@enerbit/base";
import { useSelector } from "react-redux";
import LeftMenu from "../common/LeftMenu/LeftMenu";
import ListTabs from "../common/Tabs/ListTabs";
import type { StateStorage } from "../models/store/StateStorage";
import type { TabModel } from "../models/ui/Tab";

import { BillingContainer } from "./components/Tabs/billing/BillingContainer";
import { InformationContainer } from "./components/Tabs/information/InformationContainer";
import { ServicesOrdersContainer } from "./components/Tabs/services-orders/ServicesOrdersContainer";
import TagsContainer from "./components/Tabs/tags/TagsContainer";
import UsagesContainer from "./components/Tabs/usages/components/UsagesContainer";

const AttentionPage = () => {
  const { service, estates } = useSelector(
    (state: StateStorage) => state.information
  );
  const { enerbitFrontiers } = useSelector(
    (state: StateStorage) => state.assignees
  );

  const tabs: TabModel[] = [
    {
      label: "Facturación",
      component: <BillingContainer />,
    },
    {
      label: "Consumos",
      component: <UsagesContainer />,
      // biome-ignore lint/complexity/noUselessTernary: <explanation>
      disabled: service?.started_at ? false : true,
    },
    {
      label: "Información",
      component: <InformationContainer />,
      disabled: service && estates && enerbitFrontiers ? false : true,
    },
    {
      label: "Etiquetas",
      component: <TagsContainer />,
      disabled:
        service && ((service.id && service.tags) || service.tags === null)
          ? false
          : true,
    },
    {
      label: "Órdenes de servicio",
      component: <ServicesOrdersContainer />,
      // biome-ignore lint/complexity/noUselessTernary: <explanation>
      disabled: service?.measurement_point_id ? false : true,
    },
  ];

  return (
    <AttentionPageContainer>
      <LeftMenu />
      <ContentContainer>
        <ListTabs tabs={tabs} />
      </ContentContainer>
    </AttentionPageContainer>
  );
};

export default AttentionPage;

const AttentionPageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ContentContainer = styled("div")`
  padding: 1.5rem 2.25rem 2.25rem 2rem;
  width: 100%;
`;
