import { enqueueSnackbar } from "notistack";

/**
 * Muestra una notificación basada en una condición.
 * @param condition - La condición que determina el tipo de notificación.
 * @param successMessage - El mensaje a mostrar si la condición es verdadera.
 * @param errorMessage - El mensaje a mostrar si la condición es falsa.
 */
export const showNotification = (
  condition: boolean | null,
  successMessage: string | null,
  errorMessage: string | null
) => {
  if (condition === null) {
    return;
  }
  if (condition === true && successMessage) {
    enqueueSnackbar(successMessage, {
      variant: "success",
      autoHideDuration: 5000,
    });
  } else if (condition === false && errorMessage) {
    enqueueSnackbar(errorMessage, {
      variant: "error",
      autoHideDuration: 5000,
    });
  }
};
