import { createAsyncThunk } from "@reduxjs/toolkit";

import { api, EnerbitPagination } from "@enerbit/base";
import { ApiError } from "../../../models/store/payload-types/payloadTypes";
import { DeleteTagRelationshipResponse } from "../../../models/store/response-types/DeleteTagRelationshipResponse";
import { TagRelationshipResponse } from "../../../models/store/response-types/TagRelationshipResponse";
import { Tag } from "../../../models/tags/Tags";

export const getTags = createAsyncThunk(
  "[Tags] Get Tags",
  async (_, thunkAPI) => {
    try {
      const response = await api.get<EnerbitPagination<Tag>>(
        "/electricity-supply-service/manager/electricity-supply-service/tags/"
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      const apiError = error as ApiError;
      return thunkAPI.rejectWithValue(apiError.response);
    }
  }
);

export interface TagRelationship {
  tag_id: string;
  electricity_supply_service_id: string;
}

export const createTagRelationship = createAsyncThunk(
  "[Tags] Create Tag Relationship",
  async (tagRelationship: TagRelationship, thunkAPI) => {
    try {
      const body: TagRelationship = {
        tag_id: tagRelationship.tag_id,
        electricity_supply_service_id:
          tagRelationship.electricity_supply_service_id,
      };
      const response = await api.post<EnerbitPagination<Tag>>(
        "/electricity-supply-service/manager/electricity-supply-service/electricity-supply-service-tag-relationships/",
        body
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      const apiError = error as ApiError;
      return thunkAPI.rejectWithValue(apiError.response);
    }
  }
);

export const searchTagRelationships = createAsyncThunk(
  "[Tags] Search Tag Relationships",
  async (
    params: { name: string; electricity_supply_service_id: string },
    thunkAPI
  ) => {
    try {
      const { name, electricity_supply_service_id } = params;
      const response = await api.get<
        EnerbitPagination<TagRelationshipResponse>
      >(
        `/electricity-supply-service/manager/electricity-supply-service/electricity-supply-service-tag-relationships/`,
        {
          params: {
            name,
            electricity_supply_service_id,
          },
        }
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      const apiError = error as ApiError;
      return thunkAPI.rejectWithValue(apiError.response);
    }
  }
);

export const deleteTagRelationship = createAsyncThunk(
  "[Tags] Delete Tag Relationship",
  async (tagRelationshipId: string, thunkAPI) => {
    try {
      const response = await api.delete<DeleteTagRelationshipResponse>(
        `/electricity-supply-service/manager/electricity-supply-service/electricity-supply-service-tag-relationships/${tagRelationshipId}`
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      const apiError = error as ApiError;
      return thunkAPI.rejectWithValue(apiError.response);
    }
  }
);
