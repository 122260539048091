import { enerbitColors } from "@enerbit/base";
import { Box, Typography } from "@mui/material";
import React from "react";

const EmptyStateTags: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      padding: "1rem",
      borderRadius: "12px",
    }}
  >
    <Typography
      sx={{
        color: `${enerbitColors.primary.main}`,
        fontWeight: "700",
      }}
    >
      Sin etiquetas asociadas
    </Typography>
    <Typography
      sx={{
        color: `${enerbitColors.neutral.main}`,
        fontWeight: "500",
      }}
    >
      Actualmente no se han registrado etiquetas al servicio de energía
    </Typography>
  </Box>
);

export default EmptyStateTags;
