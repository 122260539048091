import { EnerbitPagination } from "@enerbit/base";
import { Tag } from "../models/tags/Tags";

/**
 * Verifica si una etiqueta ya existe en `tagsRelationshipService` y `newTags.items`.
 * @param tagsRelationshipService - La lista de etiquetas relacionadas con el servicio.
 * @param newTags - El objeto que contiene las nuevas etiquetas.
 * @param selectedTag - La etiqueta seleccionada.
 * @returns `true` si la etiqueta ya existe, `false` en caso contrario.
 */
export const tagExists = (
  tagsRelationshipService: Tag[],
  newTags: { items: Tag[] }
): boolean => {
  return (
    tagsRelationshipService?.some((tag) =>
      newTags.items?.some((newTag) => newTag.id === tag.id)
    ) ?? false
  );
};

/**
 * Función genérica para eliminar un tag de una lista de tags.
 * @param list - La lista de etiquetas.
 * @param tagId - El ID de la etiqueta a eliminar.
 * @returns La lista de etiquetas actualizada sin la etiqueta especificada.
 */
export const removeTagFromList = <T extends { id: string }>(
  list: T[],
  tagId: string
): T[] => {
  const filteredList = list.filter((tag) => tag.id !== tagId);
  return filteredList;
};

interface ValidationResult {
  isValid: boolean;
  errorMessage: string;
}

export const validateTags = (
  tags?: EnerbitPagination<Tag>
): ValidationResult => {
  if (!tags || tags.items.length === 0) {
    return {
      isValid: false,
      errorMessage: "La lista de etiquetas no puede estar vacía.",
    };
  }
  return { isValid: true, errorMessage: "" };
};
