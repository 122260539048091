import { api, Estate } from "@enerbit/base";
import { getServerErrorMessage } from "@enerbit/base/common/utils/serverErrorMessages";
import {
  ActionReducerMapBuilder,
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosInstance } from "axios";
import { CregSubscriber } from "../../models/electricity-supply-services/CregSubscriber";
import { IServiceRelationships } from "../../models/electricity-supply-services/IServiceRelationships";
import {
  FixedEnerbitElectricitySupplyService,
  Service,
} from "../../models/electricity-supply-services/ServiceInformation";
import { InformationFormValues } from "../../models/information/InformationFormValues";
import {
  RelayStatus,
  ServiceErrorStatus,
} from "../../models/information/RelayStatus";
import { PayloadEditForm } from "../../models/payloads/information/PayloadEditForm";
import { PayloadPatchCregSubscriber } from "../../models/payloads/information/PayloadPatchCregSubscriber";

import { InformationState } from "../../models/store/states/InformationState";
import { StateStorage } from "../../models/store/StateStorage";
import { AssetOwnership } from "../../models/sui-utils/AssetOwnerships";
import { SuiSocialStratum } from "../../models/sui-utils/SocialStratum";
import { Zone } from "../../models/sui-utils/Zone";
import { Tag } from "../../models/tags/Tags";
import { getServiceRelationships } from "../actions/electricity-supply-service/electricity-supply-service.actions";
import {
  onEnerbitServiceAgreementChanged,
  onEnerbitServiceAgreementChangedPlan,
} from "./planManagementSlice";

//Obtener electricity supply service por id
export const getEssById = createAsyncThunk(
  //action type string
  "settlements/getEssById",
  //callback function
  async (
    electricity_supply_service_id: string,
    thunkAPI
  ): Promise<Service | any> => {
    try {
      const { data }: { data: Service } = await (api as AxiosInstance).get(
        `/electricity-supply-service/manager/electricty-supply-services/${electricity_supply_service_id}`
      );
      const plan =
        data.enerbit_electricity_supply_service?.enerbit_service_agreement;
      if (plan) {
        thunkAPI.dispatch(onEnerbitServiceAgreementChanged(plan));
        thunkAPI.dispatch(onEnerbitServiceAgreementChangedPlan(plan));
      }
      return data;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//Obtener estate por id
export const getEstates = createAsyncThunk(
  //action type string
  "settlements/getStates",
  //callback function
  async (_, thunkAPI): Promise<Service | any> => {
    try {
      const state = thunkAPI.getState() as StateStorage;
      const { data } = await (api as AxiosInstance).get(
        `/accounts/estates/${state.information.service?.estate.id}/`
      );
      return data;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//Obtener fixed service por id
export const getFixedService = createAsyncThunk(
  //action type string
  "settlements/getFixedService",
  //callback function
  async (
    electricity_supply_service_id: string,
    thunkAPI
  ): Promise<Service | any> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `/electricity-supply-service/manager/fixed-services/?electricity_supply_service_id=${electricity_supply_service_id}`
      );
      return data.items[0];
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//Obtener clases de servicio
export const getZones = createAsyncThunk(
  "settlements/getZones",
  async (): Promise<Zone[] | null> => {
    try {
      const { data } = await api.get(
        "/electricity-supply-service/manager/zones-sui/"
      );
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

//Obtener estratos
export const getSuiSocialStratums = createAsyncThunk(
  "settlements/getSuiSocialStratums",
  async (): Promise<SuiSocialStratum[] | null> => {
    try {
      const { data } = await api.get(
        "/electricity-supply-service/manager/sui-social-stratums/"
      );
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

//Obtener estado del servicio (conectado o desconectado)
export const getRelayStatus = createAsyncThunk(
  //action type string
  "settlements/getRelayStatus",
  //callback function
  async (meter_id: string, thunkAPI): Promise<RelayStatus | any> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `/electricity-supply-service/device/meters/${meter_id}/relay-status/`
      );

      return data;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//Obtener Propiedades de activos
export const getAssetOwnerships = createAsyncThunk(
  "settlements/getAssetOwnerships",
  async (): Promise<AssetOwnership[] | null> => {
    try {
      const { data } = await api.get(
        "/electricity-supply-service/manager/asset-ownerships/"
      );
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

export const patchCregSubscriber = createAsyncThunk(
  //action type string
  "settlements/patchCregSubscriber",
  //callback function
  async (
    payload: PayloadPatchCregSubscriber,
    thunkAPI
  ): Promise<CregSubscriber | null> => {
    const state = thunkAPI.getState() as StateStorage;
    try {
      const { data } = await (api as AxiosInstance).patch(
        `/electricity-supply-service/manager/creg-subscribers/${
          state.information.service!.creg_subscriber.id
        }/`,
        payload
      );
      //await thunkAPI.dispatch(getEssById(state.information.service!.id));
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

export const postReconnectService = createAsyncThunk(
  //action type string
  "settlements/reconnectService",
  //callback function
  async (meter_id: string, thunkAPI): Promise<Service | any> => {
    const state = thunkAPI.getState() as StateStorage;
    try {
      const { data } = await (api as AxiosInstance).post(
        `/electricity-supply-service/device/meters/${meter_id}/reconnect-power`,
        {
          meter_id,
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const initialState: InformationState = {
  service: {} as Service,
  fixedEnerbitElectricitySupplyServicesId:
    {} as FixedEnerbitElectricitySupplyService,
  openConfirmReconnectDialog: false,
  serviceStatus: {} as RelayStatus,
  isLoadingServiceStatus: false,
  isLoadingServiceReconnect: false,
  serviceReconnectMessage: "",
  serviceErrorObject: {} as ServiceErrorStatus,
  isSuccessServiceReconnect: false,
  zones: [],
  suiSocialStratums: [],
  isLoadingService: false,
  assetOwnerships: [],
  edit: false,
  body: {} as InformationFormValues,
  openConfirmDialog: false,
  isSuccessCregSubscriberPatch: false,
  openSuccessAlert: false,
  hasError: false,
  serviceError: "",
  serviceList: [],
  isLoadingServiceList: false,
  serviceListError: "",
  estates: null,
  indexTab: 0,
};

export const informationSlice = createSlice({
  name: "information",
  initialState,
  reducers: {
    setIndexTab: (state, action: PayloadAction<number>) => {
      state.indexTab = action.payload;
    },
    setEdit: (state, action: PayloadAction<PayloadEditForm>) => {
      state.edit = action.payload.edit;
      state.body = action.payload.body;
    },
    setOpenConfirmDialog: (state, action: PayloadAction<boolean>) => {
      state.openConfirmDialog = action.payload;
    },
    setResetAlerts: (state) => {
      state.hasError = false;
    },
    resetAndSaveTab: (state) => {
      const saveIndexTab = state.indexTab;
      const resetState = { ...initialState, indexTab: saveIndexTab };
      state = resetState;
    },
    setOpenSuccessAlert: (state, action: PayloadAction<boolean>) => {
      state.openSuccessAlert = action.payload;
    },
    setCleanErrorMessage: (state, action: PayloadAction<string>) => {
      state.serviceError = action.payload;
    },
    cleanServiceReconnectMessage: (state) => {
      state.serviceReconnectMessage = "";
    },
    setOpenConfirmReconnectDialog: (state, action: PayloadAction<boolean>) => {
      state.openConfirmReconnectDialog = action.payload;
    },
    updateServiceVar: (state, action: PayloadAction<Service>) => {
      state.service = action.payload;
    },
    addTagsToService: (state, { payload }: PayloadAction<Tag[]>) => {
      if (state.service) {
        state.service.tags = state.service.tags || [];
        state.service.tags.push(...payload);
      }
    },
    removeTagFromService: (
      state,
      {
        payload,
      }: PayloadAction<{
        tagId: string;
      }>
    ) => {
      const { tagId } = payload;
      if (state.service) {
        state.service.tags = state.service.tags.filter(
          (tag) => tag.id !== tagId
        );
      }
    },

    cleanStoreInformation: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InformationState>) => {
    builder
      .addCase(getEssById.pending.type, (state, action) => {
        state.service = {} as Service;
        state.isLoadingService = true;
        state.serviceError = "";
      })
      .addCase(
        getEssById.fulfilled.type,
        (state, action: PayloadAction<Service>) => {
          state.service = action.payload;
          state.isLoadingService = false;
        }
      )
      .addCase(getEssById.rejected.type, (state, { payload }: AnyAction) => {
        state.service = {} as Service;
        state.isLoadingService = false;
        if (payload.status === 404) {
          state.serviceError = "No existe servicio de electricidad";
        } else {
          state.serviceError = "Error del servidor";
        }
      })
      .addCase(getEstates.pending.type, (state, action) => {
        state.estates = null;
      })
      .addCase(
        getEstates.fulfilled.type,
        (state, action: PayloadAction<Estate>) => {
          state.estates = action.payload;
        }
      )
      .addCase(getEstates.rejected.type, (state, { payload }: AnyAction) => {
        state.estates = null;
        if (payload.status === 404) {
          state.serviceError = "No existe servicio de electricidad";
        } else {
          state.serviceError = "Error del servidor";
        }
      });
    builder
      .addCase(getFixedService.pending.type, (state, action) => {
        state.fixedEnerbitElectricitySupplyServicesId =
          {} as FixedEnerbitElectricitySupplyService;
      })
      .addCase(
        getFixedService.fulfilled.type,
        (
          state,
          action: PayloadAction<FixedEnerbitElectricitySupplyService>
        ) => {
          state.fixedEnerbitElectricitySupplyServicesId = action.payload;
        }
      )
      .addCase(
        getFixedService.rejected.type,
        (state, { payload }: AnyAction) => {
          state.fixedEnerbitElectricitySupplyServicesId =
            {} as FixedEnerbitElectricitySupplyService;
        }
      )
      .addCase(getZones.pending.type, (state, action) => {
        state.zones = [];
      })
      .addCase(
        getZones.fulfilled.type,
        (state, action: PayloadAction<Zone[]>) => {
          state.zones = action.payload;
        }
      )
      .addCase(getZones.rejected.type, (state, action) => {
        state.zones = [];
      })
      .addCase(getSuiSocialStratums.pending.type, (state, action) => {
        state.suiSocialStratums = [];
      })
      .addCase(
        getSuiSocialStratums.fulfilled.type,
        (state, action: PayloadAction<SuiSocialStratum[]>) => {
          state.suiSocialStratums = action.payload;
        }
      )
      .addCase(getSuiSocialStratums.rejected.type, (state, action) => {
        state.suiSocialStratums = [];
      })
      .addCase(getRelayStatus.pending.type, (state, action) => {
        state.serviceStatus = {} as RelayStatus;
        state.isLoadingServiceStatus = true;
        state.serviceErrorObject = {} as ServiceErrorStatus;
        state.serviceErrorObject.message = "";
        state.serviceErrorObject.status = "";
      })
      .addCase(
        getRelayStatus.fulfilled.type,
        (state, { payload }: AnyAction) => {
          state.serviceErrorObject = {} as ServiceErrorStatus;
          state.serviceStatus = payload;
          state.isLoadingServiceStatus = false;
        }
      )
      .addCase(
        getRelayStatus.rejected.type,
        (state, { payload }: AnyAction) => {
          state.serviceStatus = {} as RelayStatus;
          state.serviceErrorObject = {} as ServiceErrorStatus;
          state.isLoadingServiceStatus = false;
          state.serviceErrorObject.message =
            "Se agotó el tiempo de espera. Intenta refrescar esta página.";
          state.serviceErrorObject.status = "timeout";
          if (payload.data.detail) {
            if (
              payload.data.detail.startsWith("Device") |
              payload.data.detail.startsWith("The meter")
            ) {
              state.serviceErrorObject.message =
                "El smartBit está desconectado. No es posible conocer el estado del medidor.";
              state.serviceErrorObject.status = "disconnected";
            }
          }
        }
      )
      .addCase(getAssetOwnerships.pending.type, (state, action) => {
        state.assetOwnerships = [];
      })
      .addCase(
        getAssetOwnerships.fulfilled.type,
        (state, action: PayloadAction<AssetOwnership[]>) => {
          state.assetOwnerships = action.payload;
        }
      )
      .addCase(getAssetOwnerships.rejected.type, (state, action) => {
        state.assetOwnerships = [];
      })
      .addCase(patchCregSubscriber.pending.type, (state, action) => {
        state.isSuccessCregSubscriberPatch = false;
        state.hasError = false;
      })
      .addCase(
        patchCregSubscriber.fulfilled.type,
        (state, action: PayloadAction<Service>) => {
          state.isSuccessCregSubscriberPatch = true;
          state.hasError = false;
        }
      )
      .addCase(patchCregSubscriber.rejected.type, (state, action) => {
        state.isSuccessCregSubscriberPatch = false;
        state.hasError = true;
      })
      .addCase(postReconnectService.pending.type, (state, action) => {
        state.isLoadingServiceReconnect = true;
      })
      .addCase(postReconnectService.fulfilled.type, (state, action) => {
        state.serviceReconnectMessage = "Envío de comando exitoso";
        state.isLoadingServiceReconnect = false;
        state.isSuccessServiceReconnect = true;
      })
      .addCase(postReconnectService.rejected.type, (state, action) => {
        state.isSuccessServiceReconnect = false;
        state.isLoadingServiceReconnect = false;
        state.serviceReconnectMessage = "Error al enviar el comando";
      })
      .addCase(getServiceRelationships.pending.type, (state, action) => {
        state.serviceList = [];
        state.isLoadingServiceList = true;
        state.serviceListError = "";
      })
      .addCase(
        getServiceRelationships.fulfilled.type,
        (state, action: PayloadAction<IServiceRelationships[]>) => {
          state.serviceList = action.payload;
          state.isLoadingServiceList = false;
        }
      )
      .addCase(
        getServiceRelationships.rejected.type,
        (state, { payload }: AnyAction) => {
          state.serviceList = [];
          state.isLoadingServiceList = false;
          state.serviceListError = getServerErrorMessage(payload.status);
        }
      );
  },
});

export const {
  setEdit,
  setIndexTab,
  setOpenConfirmDialog,
  setResetAlerts,
  resetAndSaveTab,
  setOpenSuccessAlert,
  setCleanErrorMessage,
  cleanServiceReconnectMessage,
  setOpenConfirmReconnectDialog,
  updateServiceVar,
  cleanStoreInformation,
  addTagsToService,
  removeTagFromService,
} = informationSlice.actions;

export default informationSlice.reducer;

//export service
export const serviceStartedAd = (state: StateStorage) =>
  state.information.service?.started_at;
