import { Box, ElectricitySupplyService, Grid } from "@enerbit/base";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { mountedDisassembleAppElectricityService } from "../../../../helpers/spa/spa.helper";
import { StateStorage } from "../../../../models/store/StateStorage";

export const InformationContainer = () => {
  const { service, estates } = useSelector(
    (state: StateStorage) => state.information
  );
  const { enerbitFrontiers } = useSelector(
    (state: StateStorage) => state.assignees
  );

  useEffect(() => {
    if (estates != null && service) {
      mountedDisassembleAppElectricityService({
        leadUserEmail: "",
        serviceAccountId: "",
        userId: "",
        onCloseModal: (
          electricitySupplyService: ElectricitySupplyService
        ) => {},
        service: service,
        estate: estates,
        frontiers: enerbitFrontiers,
      });
    }
  }, [estates]);

  return (
    <Box>
      <Grid container>
        <Box
          sx={{ width: "100%" }}
          id="single-spa-application:app-electricity-supply-service-create"
        ></Box>
      </Grid>
    </Box>
  );
};
