import { useEffect } from "react";
import { useSelector } from "react-redux";
import { mountedDisassembleAppServicesOrders } from "../../../../helpers/spa/spa.helper";
import { StateStorage } from "../../../../models/store/StateStorage";

export const ServicesOrdersContainer = () => {
  const { service } = useSelector((state: StateStorage) => state.information);

  useEffect(() => {
    if (service && service.id)
      mountedDisassembleAppServicesOrders(service.measurement_point_id);
  }, []);

  return <div id="single-spa-application:app-services-orders" />;
};
