import { enerbitColors, NotiProvider } from "@enerbit/base";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Box, Grid, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../../models/store/StateStorage";

import {
  addTagServiceRelationship,
  cleanStoreTags,
  deleteTag,
} from "../../../../store/slices/tagsSlice";
import { AppDispatch } from "../../../../store/store";
import { showNotification } from "../../../../utils/showNotification";
import EmptyStateTags from "./EmptyStateTags";
import TagsHeader from "./TagsHeader";
import TagsList from "./TagsList";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
    borderRadius: "12px 12px 0px 0px",
  },
  border: `1px solid ${enerbitColors.neutral[300]}`,
  borderRadius: "12px",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  borderRadius: "12px",
  padding: "16px",
  "& .MuiAccordionSummary-content": {
    margin: "0px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "transparent",
  borderTop: `1px solid ${enerbitColors.neutral[300]}`,
  borderRadius: "0px 0px 12px 12px",
}));

//TODO: Deuda tecnica - Se debe cambiar el back se realcion tags con servicio de electricidad para que el back maneja multiples tags asociados a un servicio y en base a ese cambio relizar las modificaciones aqui

const TagsContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { service } = useSelector((state: StateStorage) => state.information);
  const { tagsRelationshipService } = useSelector(
    (state: StateStorage) => state.tags
  );
  const [expanded, setExpanded] = useState<string | boolean>("panel1");
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const {
    successCreateTagRelationship,
    errorCreateTagRelationship,
    isDoneCreateTagRelationship,
    successDeleteTagRelationship,
    errorDeleteTagRelationship,
    isDoneDeleteTagRelationship,
  } = useSelector((state: StateStorage) => state.tags);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    if (
      successCreateTagRelationship !== null ||
      errorCreateTagRelationship !== null
    ) {
      showNotification(
        isDoneCreateTagRelationship,
        successCreateTagRelationship,
        errorCreateTagRelationship
      );
    }
  }, [
    isDoneCreateTagRelationship,
    successCreateTagRelationship,
    errorCreateTagRelationship,
  ]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    if (
      successDeleteTagRelationship !== null ||
      errorDeleteTagRelationship !== null
    ) {
      showNotification(
        isDoneDeleteTagRelationship,
        successDeleteTagRelationship,
        errorDeleteTagRelationship
      );
    }
  }, [
    isDoneDeleteTagRelationship,
    successDeleteTagRelationship,
    errorDeleteTagRelationship,
  ]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (service?.tags) {
      dispatch(addTagServiceRelationship(service.tags));
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(cleanStoreTags());
    };
  }, [dispatch]);

  return (
    <>
      <NotiProvider>
        <TagsHeader openModal={openModal} setOpenModal={setOpenModal} />
        <Box sx={{ mt: "1rem" }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid container spacing={2} className="content-header-accordion">
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: `${enerbitColors.neutral[600]}`,
                      fontWeight: "400",
                    }}
                  >
                    ID del servicio de energia:
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                  <Typography
                    sx={{
                      color: `${enerbitColors.neutral[600]}`,
                      fontWeight: "700",
                    }}
                  >
                    {service?.id}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              style={{ padding: "1.875rem 1rem 1rem 1.875rem" }}
            >
              {tagsRelationshipService &&
              tagsRelationshipService.length === 0 ? (
                <EmptyStateTags />
              ) : (
                tagsRelationshipService && (
                  <TagsList
                    electricitySupplyServiceServiceId={service?.id}
                    listName="tagsRelationshipService"
                    handleDeleteTag={(id: string) => {
                      dispatch(
                        deleteTag({
                          listName: "tagsRelationshipService",
                          tagId: id,
                        })
                      );
                    }}
                    tags={tagsRelationshipService}
                    showDeleteModal={true}
                  />
                )
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </NotiProvider>
    </>
  );
};

export default TagsContainer;
