import {
  Box,
  Modal,
  Typography,
  enerbitColors,
  useFormik,
  yup,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { StateStorage } from "../../../../../models/store/StateStorage";
import { FormAddTagModal } from "./FormAddTagModal";

export type ModalChangePlanProps = {
  open: boolean;
  handleClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
};

export const ModalAddTag = ({ open, handleClose }: ModalChangePlanProps) => {
  const { newTags } = useSelector((state: StateStorage) => state.tags);

  const validationSchema = yup.object({
    tag: yup.string().required("La etiqueta es requerida"),
  });

  const formik = useFormik({
    initialValues: {
      tag: "",
      tags: newTags?.items || [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const handleSubmit = () => {};
    },
  });

  const handleAddTag = () => {
    if (!formik.errors.tag && formik.values.tag) {
      formik.setFieldValue("tags", [
        ...(formik.values.tags || []),
        { label: formik.values.tag },
      ]);
      formik.setFieldValue("tag", "");
      formik.setFieldTouched("tag", false);
    } else {
      formik.setFieldTouched("tag", true);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal-container">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color={enerbitColors.primary.main}
              align="center"
              fontWeight="400"
            >
              Agregar etiquetas
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color={enerbitColors.neutral[700]}
              align="center"
              fontWeight="400"
            >
              Etiqueta:
            </Typography>
          </Box>

          <FormAddTagModal handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
};
