import { AddCircleOutlineIcon, theme } from "@enerbit/base";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { ModalAddTag } from "./Modals/ModalAddTag";

interface TagsHeaderProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

const TagsHeader: React.FC<TagsHeaderProps> = ({ openModal, setOpenModal }) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          color={theme.palette.primary.main}
          sx={{ fontSize: "1.5rem", fontWeight: "700", lineHeight: "1.5" }}
        >
          Etiquetas del servicio
          <span>&nbsp;</span>
        </Typography>
        <Button
          fullWidth
          className="Container-button-add-tag"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <AddCircleOutlineIcon
              sx={{ width: "1rem", height: "1rem", mr: "0.3rem" }}
            />
            <Typography
              color={theme.palette.primary.main}
              sx={{ fontWeight: "700" }}
            >
              Agregar etiqueta
            </Typography>
          </Box>
        </Button>
      </Box>
      <ModalAddTag
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
};

export default TagsHeader;
