import {
  Autocomplete,
  Box,
  Button,
  FormGroup,
  Grid,
  TextField,
  theme,
  Typography,
  useFormik,
} from "@enerbit/base";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tagExists, validateTags } from "../../../../../helpers/tagsHelpers";
import { StateStorage } from "../../../../../models/store/StateStorage";
import { Tag } from "../../../../../models/tags/Tags";
import {
  createTagRelationship,
  getTags,
} from "../../../../../store/actions/tags/tags.actions";
import {
  addNewTag,
  addTagServiceRelationship,
  cleanCreateTagRelationshipStatus,
  cleanStoreNewTags,
  deleteTag,
} from "../../../../../store/slices/tagsSlice";
import { AppDispatch } from "../../../../../store/store";
import TagsList from "../TagsList";

import { ApiErrorResponse } from "../../../../../models/store/payload-types/payloadTypes";
import { addTagsToService } from "../../../../../store/slices/informationSlice";

export type FormAddTagModalProps = {
  handleClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
};

export const FormAddTagModal = ({ handleClose }: FormAddTagModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { tags, isLoadingTags, newTags, tagsRelationshipService } = useSelector(
    (state: StateStorage) => state.tags
  );

  const { service } = useSelector((state: StateStorage) => state.information);

  const resetForm = () => {
    formik.resetForm();
    setSelectedTag(null);
    if (handleClose) {
      handleClose({}, "backdropClick");
    }
    dispatch(cleanStoreNewTags());
  };

  const formik = useFormik({
    initialValues: {
      tag: "",
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const { isValid, errorMessage } = validateTags(newTags);
        if (!isValid) {
          formik.setFieldError("tag", errorMessage);
          setIsSubmitting(false);
          return;
        }

        if (newTags && newTags.items.length > 0) {
          await handleNewTags();
        }

        resetForm();
      } catch (error) {
        console.error("Error en onSubmit:", error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    dispatch(getTags());
  }, [dispatch]);

  const handleCreateTagRelationship = async (tagId: string) => {
    if (service) {
      const tagRelationship = {
        tag_id: tagId,
        electricity_supply_service_id: service.id,
      };
      try {
        // TODO: Buscar una mejor manera de darle manejo a todo este try
        const result = await dispatch(createTagRelationship(tagRelationship));
        if (result.type === "[Tags] Create Tag Relationship/rejected") {
          const errorPayload = result as ApiErrorResponse;
          const errorDetail =
            errorPayload.payload.data.detail ||
            "Error creating tag relationship";
          throw new Error(errorDetail);
        }
      } catch (error) {
        console.error("Error en handleCreateTagRelationship:", error);
        throw error;
      }
    }
  };

  const handleNewTags = async (): Promise<void> => {
    const tagsToAdd = newTags?.items.filter(
      (tag) => !tagExists(tagsRelationshipService, { items: [tag] })
    );

    if (tagsToAdd && tagsToAdd.length > 0) {
      try {
        const promises = tagsToAdd.map((tag) => {
          return handleCreateTagRelationship(tag.id);
        });

        await Promise.all(promises);
        dispatch(addTagServiceRelationship(tagsToAdd));
        dispatch(addTagsToService(tagsToAdd));
        dispatch(cleanCreateTagRelationshipStatus());
      } catch (error) {
        console.error("handleNewTags: error creating relationship", error);
        if (handleClose) {
          handleClose({}, "backdropClick");
        }
        throw error;
      }
    } else {
      formik.setFieldError(
        "tag",
        "La etiqueta ya existe en el servicio de electricidad."
      );
      throw new Error("La etiqueta ya existe en el servicio de electricidad.");
    }
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const handleAddTag = () => {
    if (!selectedTag) {
      formik.setFieldError("tag", "Por favor seleccione una etiqueta.");
      return;
    }
    formik.setFieldError("tag", "");

    // Verificar si el tag ya existe en newTags.items
    const tagAlreadyExists = newTags?.items?.some(
      (tag) => tag.id === selectedTag.id
    );

    if (tagAlreadyExists) {
      formik.setFieldError(
        "tag",
        "La etiqueta ya existe en la lista, por favor seleccione otra diferente."
      );
      return;
    }

    if (!tagExists(tagsRelationshipService, { items: [selectedTag] })) {
      dispatch(addNewTag(selectedTag));
    } else {
      formik.setFieldError(
        "tag",
        "La etiqueta ya existe en el servicio de electricidad."
      );
    }
    setSelectedTag(null);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <FormGroup>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={8}>
            <Autocomplete
              id="tagsId"
              fullWidth
              loading={isLoadingTags}
              options={tags?.items ?? []}
              value={selectedTag}
              onChange={(_event, newValue) => {
                setSelectedTag(newValue);
                formik.setFieldError("tag", "");
              }}
              autoHighlight
              getOptionLabel={(option) => option?.name ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="tagsId"
                  className="TextField-without-border-radius"
                  variant="outlined"
                  placeholder="Seleccione la Etiqueta"
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "tag-input",
                  }}
                  error={!!formik.errors.tag}
                  helperText={formik.errors.tag}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <Button
              className="Container-button-add-tag"
              onClick={handleAddTag}
              sx={{
                flexShrink: 0,
                paddingTop: "0px",
                width: "100% !important",
                height: "3.35625rem",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <AddCircleOutlineIcon
                  sx={{ width: "1rem", height: "1rem", mr: "0.3rem" }}
                />
                <Typography
                  color={theme.palette.primary.main}
                  sx={{ fontWeight: "700" }}
                >
                  Agregar etiqueta
                </Typography>
              </Box>
            </Button>
          </Grid>
        </Grid>
      </FormGroup>
      <Box sx={{ margin: "1.5rem 0 0 0" }}>
        <TagsList
          tags={newTags?.items}
          listName="newTags"
          handleDeleteTag={(id: string) => {
            dispatch(deleteTag({ listName: "newTags", tagId: id }));
          }}
          showDeleteModal={false}
        />
      </Box>
      <Grid container spacing={4} sx={{ marginTop: "20px" }}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={() => {
              if (handleClose) {
                handleClose({}, "backdropClick");
              }
            }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleButtonClick}
            disabled={isSubmitting}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
