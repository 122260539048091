import { configureStore, createAction } from "@reduxjs/toolkit";
import accountSlice from "./slices/accountSlice";
import assigneesSlice from "./slices/assigneesSlice";
import billingSlice from "./slices/billingSlice";
import informationSlice from "./slices/informationSlice";
import planManagementSlice from "./slices/planManagementSlice";
import tagsSlice from "./slices/tagsSlice";
import usagesSlice from "./slices/usagesSlice";

// Acción para reiniciar el estado
export const resetState = createAction("resetState");

export const store = configureStore({
  reducer: {
    billing: billingSlice,
    information: informationSlice,
    usages: usagesSlice,
    planManagement: planManagementSlice,
    assignees: assigneesSlice,
    account: accountSlice,
    tags: tagsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

store.dispatch(resetState());

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
