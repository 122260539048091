import { IconButton, darken, styled } from "@enerbit/base";

export const StyledIconButton = styled(IconButton)(({ theme, color }) => {
  let bgColor;
  let hoverColor;
  switch (color) {
    case "info":
      bgColor = "#007BFF";
      hoverColor = darken(bgColor, 0.1);
      break;
    case "default":
      bgColor = "#6C757D";
      hoverColor = darken(bgColor, 0.1);
      break;
    default:
      bgColor = theme.palette[color!]?.main;
      hoverColor = theme.palette[color!]?.dark;
      break;
  }

  return {
    borderRadius: "10px",
    width: 40,
    height: 40,
    color: "white",
    backgroundColor: bgColor,
    "& svg": {
      color: "white",
    },
    "&:hover": {
      backgroundColor: hoverColor,
    },
  };
});
