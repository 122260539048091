import {
  Box,
  CircularProgress,
  CustomAlert,
  DownloadOutlinedIcon,
  LoadingButton,
  Typography,
  formatterPeso,
  theme,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../../../../models/store/StateStorage";
import { downloadInvoiceGroup } from "../../../../../../store/actions/billing/billing.actions";
import { AppDispatch } from "../../../../../../store/store";

export const HeadTableInvoice = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    totalOWed,
    isLoadingTotalOwed,
    errorTotalOwed,
    isLoadingDocumentGroupDownloaded,
  } = useSelector((state: StateStorage) => state.billing);

  const handleDownloadClick = (documentIds: string[]) => {
    dispatch(downloadInvoiceGroup(documentIds));
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography
          color={theme.palette.primary.main}
          sx={{ fontSize: "28px", fontWeight: "700", mb: "10px" }}
        >
          Histórico de Facturación<span>&nbsp;</span>
        </Typography>
        <Typography
          color={theme.palette.primary.main}
          sx={{ fontSize: "18px", fontWeight: "400" }}
        >
          Elige el periodo que quieres consultar
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {errorTotalOwed ? (
          <CustomAlert
            severity="error"
            text={errorTotalOwed}
            onClose={() => null}
          />
        ) : isLoadingTotalOwed ? (
          <Box className="Loading-flex">
            <CircularProgress />
          </Box>
        ) : (
          <Box
            className="Container-outstanding-debts"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F2F4F7",
              borderRadius: "16px",
              padding: "16px",
            }}
          >
            <Box>
              <Typography
                color={theme.palette.primary.main}
                sx={{ fontSize: "18px", fontWeight: "700" }}
              >
                Deuda a la fecha
              </Typography>
              <Typography
                color={theme.palette.primary.main}
                sx={{ fontSize: "28px", fontWeight: "700" }}
              >
                {formatterPeso.format(totalOWed?.total_owe.TotalOwe ?? 0)}
              </Typography>
            </Box>
            {(totalOWed?.total_owe.TotalOwe ?? 0) > 0 && (
              <Box sx={{ marginLeft: "20px" }}>
                <LoadingButton
                  color="secondary"
                  variant="contained"
                  sx={{ height: "90%" }}
                  loading={isLoadingDocumentGroupDownloaded}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadClick(
                      totalOWed?.total_owe.UnpaidDocuments ?? []
                    );
                  }}
                >
                  <DownloadOutlinedIcon sx={{ fontSize: "18px" }} />
                </LoadingButton>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
