import { DeleteIcon, enerbitColors } from "@enerbit/base";
import { Box, Card, CardContent, Chip, Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { StyledIconButton } from "../../../../common/style/StyledIconButton";
import { Tag } from "../../../../models/tags/Tags";

import { useDispatch } from "react-redux";
import { deleteTag } from "../../../../store/slices/tagsSlice";
import { AppDispatch } from "../../../../store/store";
import { ModalDeleteTag } from "./Modals/ModalDeleteTag";

interface TagsListProps {
  tags?: Tag[];
  showDeleteModal: boolean;
  electricitySupplyServiceServiceId?: string | undefined;
  listName: "newTags" | "tagsRelationshipService";
  handleDeleteTag: (id: string) => void;
}

const TagsList: React.FC<TagsListProps> = ({
  tags,
  showDeleteModal,
  electricitySupplyServiceServiceId,
  listName,
  handleDeleteTag,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [openModal, setOpenModal] = useState(false);
  const [selectedName, setSelectedName] = useState<string | null>(null);

  const handleDelete = (name: string | null, id: string) => {
    if (shouldOpenModal(name)) {
      if (name !== null) {
        openDeleteModal(name);
      }
    } else {
      deleteTagById(id);
    }
  };

  const shouldOpenModal = (name: string | null): boolean => {
    return name !== null && listName === "tagsRelationshipService";
  };

  const openDeleteModal = (name: string) => {
    setSelectedName(name);
    setOpenModal(true);
  };

  const deleteTagById = (id: string) => {
    if (handleDeleteTag) {
      handleDeleteTag(id);
    } else {
      dispatch(deleteTag({ listName, tagId: id }));
    }
  };

  return (
    <>
      <Stack
        spacing={1}
        alignItems="left"
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        style={{ width: "100%" }}
      >
        {tags?.map((tag, index) => (
          <Card
            key={index}
            sx={{
              display: "flex",
              m: "0 1.8rem 1rem 0 !important",
              border: `1px solid ${enerbitColors.neutral[200]}`,
              boxShadow: "none",
              borderRadius: "12px",
              flex: "0 1 auto",
              minWidth: "auto",
              width: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  pb: "1rem !important",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Tooltip key={index} title={tag.name}>
                    <Chip
                      label={tag.name}
                      sx={{
                        background: "#EDF4FF",
                        color: "#163059",
                        height: "100%",
                        borderRadius: "8px",
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        mr: "1.9rem !important",
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title="Borrar tag"
                    onClick={() => handleDelete(tag.name, tag.id)}
                  >
                    <StyledIconButton
                      sx={{ width: 34, height: 34, flexShrink: 0 }}
                      color="error"
                      aria-label="Borrar tag"
                    >
                      <DeleteIcon />
                    </StyledIconButton>
                  </Tooltip>
                </Box>
              </CardContent>
            </Box>
          </Card>
        ))}
      </Stack>
      {showDeleteModal && selectedName && (
        <ModalDeleteTag
          open={openModal}
          name={selectedName}
          electricitySupplyServiceServiceId={electricitySupplyServiceServiceId}
          handleClose={() => {
            setOpenModal(false);
            setSelectedName(null);
          }}
        />
      )}
    </>
  );
};

export default TagsList;
